import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCloseToolbar, setMarkupsToolbar } from '../../actions/toolbar';
import { issueContext } from './issueContext';
import {
    createComboButton,
    createConfig,
    createColor,
    createEditMode,
    IcWidth,
    IcCheck,
    IcColor,
    IcOpacity,
    IcTools,
    IcClose,
    IcCaretUp,
    IcCaretDown,
    IcArrow,
    IcCloud,
    IcCircle,
    IcRectangle,
    IcPolyline,
    IcFreeHand,
    IcHighlight,
    IcText,
    IcDimension,
} from '../../utils/issue';
import locale from '../../utils/localization.json';
import log from '../../utils/log';
import { restoreSectionPlane } from '../../utils/forge';

const IssueContext = ({ children }) => {
    const [issueView, setIssueView] = useState('all');
    const [state, setState] = useState();
    const [markup, setMarkup] = useState();

    const dispatch = useDispatch();

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const { closeToolbar, markupsToolbar } = useSelector(
        (state) => state.toolbars
    );

    const setScreenshot = (markupExt, toolbar) => {
        if (viewer?.model) {
            try {
                // current view state (zoom, direction, sections)
                setState(viewer?.getState());
                // markups we just created as a string
                setMarkup(markupExt?.generateData());

                // get screenshot
                var screenshot = new Image();
                screenshot.onload = function () {
                    // prepare to render the markups
                    var canvas = document.getElementById('new-screenshot');
                    if (canvas) {
                        canvas.width = viewer?.container?.clientWidth;
                        canvas.height = viewer?.container?.clientHeight;
                    }
                    var ctx = canvas?.getContext('2d');
                    ctx?.clearRect(0, 0, canvas?.width, canvas?.height);
                    ctx?.drawImage(
                        screenshot,
                        0,
                        0,
                        canvas?.width,
                        canvas?.height
                    );

                    markupExt?.renderToCanvas(ctx, () => {
                        markupExt?.leaveEditMode();
                        markupExt?.hide();
                        toolbar?.setVisible(false);
                    });
                };

                // Get the full image
                viewer?.getScreenShot(
                    viewer?.container?.clientWidth,
                    viewer?.container?.clientHeight,
                    (blobURL) => {
                        screenshot.src = blobURL;
                    }
                );
            } catch (err) {
                log.error(err);
            }
        }
    };

    const handleCreateMarkup = () => {
        if (viewer?.model) {
            try {
                let markupExt = viewer?.getExtension(
                    'Autodesk.Viewing.MarkupsCore'
                );
                markupExt?.hide();
                markupExt?.enterEditMode();

                // Create a new toolbar if it doesn't exist
                if (!markupsToolbar) {
                    const toolbar = new window.Autodesk.Viewing.UI.ToolBar(
                        'markupsToolbar'
                    );

                    // Save group
                    var saveGroup = new window.Autodesk.Viewing.UI.ControlGroup(
                        'save'
                    );
                    toolbar.addControl(saveGroup);

                    // Save
                    var saveButton = new window.Autodesk.Viewing.UI.Button(
                        'saveButton'
                    );
                    saveButton.onClick = () =>
                        setScreenshot(markupExt, toolbar);

                    saveButton.container.children[0].innerHTML = IcCheck;
                    saveButton.setToolTip(locale[lang].save);
                    saveGroup.addControl(saveButton);

                    // Edit Style group
                    var configGroup =
                        new window.Autodesk.Viewing.UI.ControlGroup(
                            'editStyle'
                        );
                    toolbar.addControl(configGroup);

                    // Width
                    var width = createComboButton(
                        'width',
                        locale[lang].width,
                        IcWidth,
                        configGroup
                    );

                    createConfig(
                        viewer,
                        'width',
                        'increase',
                        IcCaretUp,
                        width,
                        lang
                    );
                    createConfig(
                        viewer,
                        'width',
                        'decrease',
                        IcCaretDown,
                        width,
                        lang
                    );

                    // Color
                    var color = createComboButton(
                        'color',
                        locale[lang].color,
                        IcColor,
                        configGroup
                    );

                    createColor(viewer, 'red', '#ff0000', color);
                    createColor(viewer, 'yellow', '#FFFF00', color);
                    createColor(viewer, 'green', '#008000', color);
                    createColor(viewer, 'blue', '#0000FF', color);
                    createColor(viewer, 'black', '#000000', color);

                    // Opacity
                    var opacity = createComboButton(
                        'opacity',
                        locale[lang].opacity,
                        IcOpacity,
                        configGroup
                    );

                    createConfig(
                        viewer,
                        'opacity',
                        'increase',
                        IcCaretUp,
                        opacity,
                        lang
                    );
                    createConfig(
                        viewer,
                        'opacity',
                        'decrease',
                        IcCaretDown,
                        opacity,
                        lang
                    );

                    // Edit Modes group
                    var editModesGroup =
                        new window.Autodesk.Viewing.UI.ControlGroup(
                            'allEditModes'
                        );
                    toolbar.addControl(editModesGroup);

                    // Edit Modes
                    var editModes = createComboButton(
                        'editModes',
                        locale[lang].tools,
                        IcTools,
                        editModesGroup
                    );

                    // Arrow
                    createEditMode(
                        'arrow',
                        editModes,
                        IcArrow,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeArrow,
                        markupExt,
                        lang
                    );

                    // Cloud
                    createEditMode(
                        'cloud',
                        editModes,
                        IcCloud,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeCloud,
                        markupExt,
                        lang
                    );

                    // Circle
                    createEditMode(
                        'circle',
                        editModes,
                        IcCircle,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeCircle,
                        markupExt,
                        lang
                    );

                    // Rectangle
                    createEditMode(
                        'rectangle',
                        editModes,
                        IcRectangle,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeRectangle,
                        markupExt,
                        lang
                    );

                    // Polyline
                    createEditMode(
                        'polyline',
                        editModes,
                        IcPolyline,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModePolyline,
                        markupExt,
                        lang
                    );

                    // Freehand
                    createEditMode(
                        'freehand',
                        editModes,
                        IcFreeHand,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeFreehand,
                        markupExt,
                        lang
                    );

                    // Highlight
                    createEditMode(
                        'highlight',
                        editModes,
                        IcHighlight,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeHighlight,
                        markupExt,
                        lang
                    );

                    // Text
                    createEditMode(
                        'text',
                        editModes,
                        IcText,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeText,
                        markupExt,
                        lang
                    );

                    // Dimension
                    createEditMode(
                        'dimension',
                        editModes,
                        IcDimension,
                        window.Autodesk.Viewing.Extensions.Markups.Core
                            .EditModeDimension,
                        markupExt,
                        lang
                    );

                    // appent toolbar to viewer
                    viewer?.container?.appendChild(toolbar?.container);
                    dispatch(setMarkupsToolbar(toolbar));
                } else markupsToolbar?.setVisible(true);

                let styleObject = markupExt?.getStyle();
                let style = styleObject['stroke-width'];
                styleObject['stroke-width'] = style * 2;

                markupExt?.setStyle(styleObject);
            } catch (err) {
                log.error(err);
            }
        }
    };

    const handleRestoreScreenshot = () => {
        try {
            let markupExt = viewer?.getExtension(
                'Autodesk.Viewing.MarkupsCore'
            );
            viewer?.restoreState(state);

            if (state.cutplanes.length > 1) {
                // restoreSectionBox(viewer, state.cutplanes);
                const sectionExt = viewer.getExtension('Autodesk.Section');
                sectionExt.sectionBoxButton.container.click();
            } else if (state.cutplanes.length === 1)
                restoreSectionPlane(viewer, state.cutplanes[0]);

            setTimeout(() => {
                if (markup) {
                    // show markups
                    markupExt?.show();
                    // show the markups on a layer
                    markupExt?.loadMarkups(markup, 'layer1');

                    markupsToolbar?.setVisible(false);

                    if (!closeToolbar) {
                        const toolbar = new window.Autodesk.Viewing.UI.ToolBar(
                            'closeMarkupsToolbar'
                        );

                        // Close Markup group
                        var closeMarkupGroup =
                            new window.Autodesk.Viewing.UI.ControlGroup(
                                'closeMarkup'
                            );
                        toolbar.addControl(closeMarkupGroup);

                        // Close
                        var closeMarkupButton =
                            new window.Autodesk.Viewing.UI.Button(
                                'closeMarkupButton'
                            );
                        closeMarkupButton.onClick = (ev) => {
                            // Execute an action here
                            // hide markups (and restore Viewer tools)
                            markupExt?.hide();
                            toolbar?.setVisible(false);
                        };
                        closeMarkupButton.container.children[0].innerHTML =
                            IcClose;
                        closeMarkupButton.setToolTip(locale[lang].close);
                        closeMarkupGroup.addControl(closeMarkupButton);

                        // appent toolbar to viewer
                        viewer?.container?.appendChild(toolbar?.container);
                        dispatch(setCloseToolbar(toolbar));
                    } else closeToolbar?.setVisible(true);
                }
            }, 500);
        } catch {}
    };

    return (
        <issueContext.Provider
            value={{
                issueView,
                setIssueView,
                state,
                setState,
                markup,
                setMarkup,
                setScreenshot,
                handleCreateMarkup,
                handleRestoreScreenshot,
            }}
        >
            {children}
        </issueContext.Provider>
    );
};

export default IssueContext;
