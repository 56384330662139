import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getAllLeafComponents } from '../../utils/forge';
import { IcSelectArrow } from './../../share/icons';
import locale from '../../utils/localization.json';

const Filter = () => {
    const [properties, setProperties] = useState([]);
    const [selectedPropery, setSelectedPropery] = useState();
    const [values, setValues] = useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);

    useEffect(() => {
        getAllLeafComponents(viewer, (dbIds) => {
            viewer?.model?.getPropertySetAsync(dbIds).then((a) => {
                const props = Object.entries(a.map).map(
                    ([, p]) => p[0].displayName
                );

                setProperties(props);
                setSelectedPropery(null);
                setSelectedValue(null);
            });
        });

        return () => viewer?.isolate();
    }, [viewer]);

    useEffect(() => {
        getAllLeafComponents(viewer, (dbIds) => {
            viewer?.model?.getBulkProperties(
                dbIds,
                selectedPropery,
                (items) => {
                    let elements = [];
                    items.forEach((item) => {
                        item.properties.forEach(function (prop) {
                            if (
                                prop.displayName === selectedPropery &&
                                prop.displayValue === selectedValue
                            ) {
                                elements.push(item.dbId);
                            }
                        });
                    });

                    viewer?.setGhosting(false);
                    viewer?.isolate(elements);
                }
            );
        });
    }, [selectedPropery, selectedValue, viewer]);

    const handleSelectPropertyChange = async (e) => {
        setSelectedPropery(e.target.value);
        setSelectedValue(null);
        getAllLeafComponents(viewer, (dbIds) => {
            viewer?.model?.getBulkProperties(
                dbIds,
                [e.target.value],
                (items) => {
                    let updatedValues = [];
                    items.forEach((item) => {
                        item.properties.forEach(function (prop) {
                            if (prop.displayName === e.target.value) {
                                let newValue = prop.displayValue;
                                if (
                                    updatedValues.filter(
                                        (v) => v === prop.displayValue
                                    ).length === 0
                                ) {
                                    updatedValues.push(newValue);
                                    setValues([...updatedValues]);
                                }
                            }
                        });
                    });
                }
            );
        });
    };

    return (
        <div className="flex-col inline-flex gap-6 w-full">
            <div className="relative">
                <label
                    htmlFor="properties"
                    className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                >
                    {locale[lang].properties}
                </label>
                <div className="absolute right-4 top-8 text-modelic-gray-500">
                    {IcSelectArrow}
                </div>
                <select
                    id="properties"
                    className="truncate bg-modelic-gray-900 block w-full pl-3 pr-8 py-2 outline-0 text-sm font-normal font-poppins text-modelic-gray-500 border border-modelic-gray-600 rounded"
                    onChange={handleSelectPropertyChange}
                    defaultValue={locale[lang].selectProperty}
                >
                    <option value={locale[lang].selectProperty} disabled>
                        {locale[lang].selectProperty}
                    </option>
                    {properties.map((prop, index) => (
                        <option key={index} value={prop}>
                            {prop}
                        </option>
                    ))}
                </select>
            </div>
            {selectedPropery && (
                <div className="relative">
                    <label
                        htmlFor="values"
                        className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                    >
                        {locale[lang].values}
                    </label>
                    <div className="absolute right-4 top-8 text-modelic-gray-500">
                        {IcSelectArrow}
                    </div>
                    <select
                        id="values"
                        className="truncate bg-modelic-gray-900 block w-full pl-3 pr-8 py-2 outline-0 text-sm font-normal font-poppins text-modelic-gray-500 border border-modelic-gray-600 rounded"
                        value={
                            selectedValue !== null
                                ? selectedValue
                                : locale[lang].selectValue
                        }
                        onChange={(e) => setSelectedValue(e.target.value)}
                    >
                        <option value={locale[lang].selectValue} disabled>
                            {locale[lang].selectValue}
                        </option>
                        {values.map((value, index) => (
                            <option key={index} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default Filter;
