import { IcDownload } from '../../icons'
import Axios from 'axios';
import fileDownload from 'js-file-download';

export default function Download({ file }) {

    const downloadHandler = (url, filename) => {
        Axios.get(url, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, filename);
        });
    }

    return (
        <button className="hover:text-modelic-primary-main" onClick={() => downloadHandler(file?.url, file?.name)}>{IcDownload}</button>
    )
}

