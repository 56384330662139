import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { IcPlusSquare } from '../../share/icons';
import locale from '../../utils/localization.json';
import IssueCard from './IssueCard';
import { issueContext } from './../context/issueContext';

const IssueAll = ({ teamsColor }) => {
    const lang = useSelector((state) => state.language);
    const issues = useSelector((state) => state.issues);

    const { setIssueView } = useContext(issueContext);

    return (
        <div className="inline-flex flex-col gap-4 w-full">
            <button
                className="w-full h-8 rounded flex gap-3 justify-center items-center bg-modelic-primary-main text-modelic-gray-100 text-sm font-medium font-poppins leading-none"
                onClick={() => setIssueView('new')}
            >
                {locale[lang].issueNew}
                {IcPlusSquare}
            </button>
            <div className="w-full h-0 border border-modelic-gray-800" />
            {issues.length === 0 ? (
                <div className="text-modelic-gray-100 self-center text-center">
                    {locale[lang].noIssue}
                </div>
            ) : (
                issues.map((issue) => (
                    <IssueCard
                        key={issue._id}
                        issue={issue}
                        teamsColor={teamsColor}
                    />
                ))
            )}
        </div>
    );
};

export default IssueAll;
