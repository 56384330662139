import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    useLocation,
} from "react-router-dom";


import { createBrowserHistory } from 'history';
const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
const sentryAppVersion = process.env.REACT_APP_SENTRY_APP_VERSION;
const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

// const sentry_dsn = "https://531de36610b79c9f140be2fa8b0ec924@sentry.modelic.ai/6";
// const sentry_app_version = "v0.0.13-test";
// const sentry_environment = "local";

if (sentryDSN && sentryAppVersion) {
    const history = createBrowserHistory();

    Sentry.init({
        dsn: sentryDSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            Sentry.reactRouterV5BrowserTracingIntegration({
                history,
                useEffect,
                useLocation,

            }),
            Sentry.replayIntegration(),
        ],
        release: sentryAppVersion,
        environment: sentryEnvironment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}





