import React from 'react';
import { useSelector } from 'react-redux';

import ViewCard from './ViewCard';

const View = () => {
    const geometries = useSelector((state) => state.geometries);

    return (
        <div className="inline-flex flex-col gap-6 w-full">
            {geometries?.map((geom, index) => (
                <ViewCard key={index} geom={geom} index={index} />
            ))}
        </div>
    );
};

export default View;
