import clsx from "clsx";
import { useState } from "react";
import {
    IcModalClose,
    IcModalBack,
    IcModalConfirm,
    IcModalDelete,
} from '../../icons';

/* 
const props = {
    lable: '',
    title: '',
    children: <div></div>,
    tag: 'confrim' | 'delete',
    callback: () => console.log('test')
}
*/

export default function Modal({ lable, title, tag, callback, children }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button type="button" onClick={() => setShowModal(true)}>
                {lable}
            </button>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-[70%]">
                            {/*container*/}
                            <div className="border-0 rounded-lg relative flex flex-col w-full bg-modelic-gray-900 outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-4 rounded-t">
                                    <h3 className="text-2xl font-medium text-modelic-gray-100">
                                        {title}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-modelic-gray-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-modelic-gray-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            {IcModalClose}
                                        </span>
                                    </button>
                                </div>
                                {/*content*/}
                                <div className="relative p-4 flex-auto text-modelic-gray-400">
                                    {children}
                                </div>
                                {/* conditional buttons based on tag */}
                                {['confirm', 'delete'].includes(tag) &&
                                    <div className="p-4 flex justify-center items-center space-x-8">
                                        <Button tag='back' callback={() => setShowModal(false)} />
                                        <Button tag={tag} callback={callback} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }
        </>
    );
}

function Button({ tag, callback }) {
    return (
        <button className={clsx("flex justify-center items-center space-x-3 px-6 py-3 capitalize rounded hover:scale-105", {
            "text-modelic-primary-main": tag === 'back',
            "bg-modelic-primary-main text-modelic-gray-100": tag === 'confirm',
            "bg-modelic-error-dark text-modelic-gray-100": tag === 'delete',
        })}
            onClick={callback}
        >
            <span>{tag === 'back' && IcModalBack }</span>
            <span>{tag}</span>
            <span>{tag === 'confirm' ? IcModalConfirm : tag === 'delete' ? IcModalDelete : null}</span>
        </button>
    )
}