import locale from './localization.json';

export const IcColor = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 0 512 512"
    >
        <path
            d="M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3H344c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
            fill="currentColor"
            />
            </svg>`;

export const IcOpacity = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 0 512 512"
    >
        <path
            d="M448 256c0-106-86-192-192-192V448c106 0 192-86 192-192zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
            fill="currentColor"
            />
            </svg>
            `;

export const IcWidth = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 640 512"
    >
        <path
            d="M32 64c17.7 0 32 14.3 32 32l0 320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96C0 78.3 14.3 64 32 64zm214.6 73.4c12.5 12.5 12.5 32.8 0 45.3L205.3 224l229.5 0-41.4-41.4c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l96 96c12.5 12.5 12.5 32.8 0 45.3l-96 96c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L434.7 288l-229.5 0 41.4 41.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-96-96c-12.5-12.5-12.5-32.8 0-45.3l96-96c12.5-12.5 32.8-12.5 45.3 0zM640 96V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"
            fill="currentColor"
            />
            </svg>`;

export const IcTools = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 0 512 512"
    >
        <path
            d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4h54.1l109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109V104c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7H352c-8.8 0-16-7.2-16-16V102.6c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"
            fill="currentColor"
            />
            </svg>`;

export const IcCheck = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="28"
        width="28"
        viewBox="0 50 448 512"
    >
        <path
            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
            fill="currentColor"
            />
            </svg>
            `;

export const IcClose = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="28"
        width="28"
        viewBox="0 50 384 512"
    >
        <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            fill="currentColor"
            />
            </svg>
            `;

export const IcCaretUp = `
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 30 320 512"
    >
        <path
            d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"
            fill="currentColor"
            />
            </svg>`;

export const IcCaretDown = `
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 30 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
            fill="currentColor"
/></svg>`;

export const IcArrow = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 512 512"
    >
        <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" 
        fill="currentColor"/>
    </svg>`;

export const IcRectangle = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 512 512"
    >
        <path d="M464 48V464H48V48H464zM48 0H0V48 464v48H48 464h48V464 48 0H464 48z" fill="currentColor"/>
    </svg>`;

export const IcCircle = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 512 512"
    >
        <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" fill="currentColor"/>
    </svg>`;

export const IcCloud = `
    <svg
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
    >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
            <title>cloud</title> <desc>Created with Sketch Beta.</desc>
            <defs> </defs>
            <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
            >
                <g
                    id="Icon-Set"
                    transform="translate(-517.000000, -779.000000)"
                    fill="currentColor"
                >
                    <path
                        d="M539,801 L525,801 C525,801 518.963,800.381 519,795 C519.021,791.964 521.688,789.183 525,789 C525,784.858 527.65,781 532,781 C535.433,781 537.723,783.096 538.538,786.014 C543.493,785.798 546.844,789.718 547,793 C547.21,797.413 542.601,801 539,801 L539,801 Z M540.067,784.028 C538.599,781.053 535.543,779 532,779 C527.251,779 523.37,782.682 523.033,787.345 C519.542,788.34 517,791.39 517,795 C517,799.26 520.54,802.731 525,802.977 C525,802.977 538.831,803 539,803 C544.247,803 549,798.747 549,793.5 C549,788.444 545.049,784.323 540.067,784.028 L540.067,784.028 Z"
                        id="cloud"
                    ></path>
                </g>
            </g>
        </g>
    </svg>`;

export const IcPolyline = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 448 512"
    >
        <path d="M96 151.4V360.6c9.7 5.6 17.8 13.7 23.4 23.4H328.6c0-.1 .1-.2 .1-.3l-4.5-7.9-32-56 0 0c-1.4 .1-2.8 .1-4.2 .1c-35.3 0-64-28.7-64-64s28.7-64 64-64c1.4 0 2.8 0 4.2 .1l0 0 32-56 4.5-7.9-.1-.3H119.4c-5.6 9.7-13.7 17.8-23.4 23.4zM384.3 352c35.2 .2 63.7 28.7 63.7 64c0 35.3-28.7 64-64 64c-23.7 0-44.4-12.9-55.4-32H119.4c-11.1 19.1-31.7 32-55.4 32c-35.3 0-64-28.7-64-64c0-23.7 12.9-44.4 32-55.4V151.4C12.9 140.4 0 119.7 0 96C0 60.7 28.7 32 64 32c23.7 0 44.4 12.9 55.4 32H328.6c11.1-19.1 31.7-32 55.4-32c35.3 0 64 28.7 64 64c0 35.3-28.5 63.8-63.7 64l-4.5 7.9-32 56-2.3 4c4.2 8.5 6.5 18 6.5 28.1s-2.3 19.6-6.5 28.1l2.3 4 32 56 4.5 7.9z" fill="currentColor"/>
    </svg>`;

export const IcFreeHand = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 512 512"
    >
        <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" fill="currentColor" />
    </svg>`;

export const IcHighlight = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 576 512"
    >
        <path d="M315 315l158.4-215L444.1 70.6 229 229 315 315zm-187 5l0 0V248.3c0-15.3 7.2-29.6 19.5-38.6L420.6 8.4C428 2.9 437 0 446.2 0c11.4 0 22.4 4.5 30.5 12.6l54.8 54.8c8.1 8.1 12.6 19 12.6 30.5c0 9.2-2.9 18.2-8.4 25.6L334.4 396.5c-9 12.3-23.4 19.5-38.6 19.5H224l-25.4 25.4c-12.5 12.5-32.8 12.5-45.3 0l-50.7-50.7c-12.5-12.5-12.5-32.8 0-45.3L128 320zM7 466.3l63-63 70.6 70.6-31 31c-4.5 4.5-10.6 7-17 7H24c-13.3 0-24-10.7-24-24v-4.7c0-6.4 2.5-12.5 7-17z" fill="currentColor" />
    </svg>`;

export const IcText = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 448 512"
    >
        <path d="M254 52.8C249.3 40.3 237.3 32 224 32s-25.3 8.3-30 20.8L57.8 416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32h-1.8l18-48H303.8l18 48H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H390.2L254 52.8zM279.8 304H168.2L224 155.1 279.8 304z" fill="currentColor" />
    </svg>`;

export const IcDimension = `<svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 512 512"
    >
        <path d="M177.9 494.1c-18.7 18.7-49.1 18.7-67.9 0L17.9 401.9c-18.7-18.7-18.7-49.1 0-67.9l50.7-50.7 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 41.4-41.4 48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-48-48 50.7-50.7c18.7-18.7 49.1-18.7 67.9 0l92.1 92.1c18.7 18.7 18.7 49.1 0 67.9L177.9 494.1z" fill="currentColor" />
    </svg>`;

export const placeCaretAtEnd = (el) => {
    el.focus();
    if (
        typeof window.getSelection != 'undefined' &&
        typeof document.createRange != 'undefined'
    ) {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != 'undefined') {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
};

export const createEditMode = (
    name,
    comboButton,
    icon,
    EditMode,
    markupExt,
    lang
) => {
    var modeButton = new window.Autodesk.Viewing.UI.Button(`${name}Button`);
    modeButton.onClick = (ev) => {
        var editMode = new EditMode(markupExt);
        markupExt?.changeEditMode(editMode);

        let styleObject = markupExt?.getStyle();
        let width = styleObject['stroke-width'];
        let font = styleObject['font-size'];

        styleObject['stroke-width'] = width * 2;
        styleObject['font-size'] = font * 1.5;

        markupExt?.setStyle(styleObject);
    };

    const div = document.createElement('div');
    div.classList.add('w-full', 'h-full', 'flex', 'justify-center');
    div.innerHTML = icon;

    modeButton.container.children[0].appendChild(div);
    modeButton.setToolTip(locale[lang][name]);
    comboButton.addControl(modeButton);
};

export const createComboButton = (name, tooltip, icon, groupControl) => {
    var comboButton = new window.Autodesk.Viewing.UI.ComboButton(
        `${name}Button`
    );

    const div = document.createElement('div');
    div.classList.add('w-full', 'h-full', 'flex', 'justify-center');
    div.innerHTML = icon;
    div.children[0].classList.add('self-center');

    comboButton.container.children[2].appendChild(div);
    comboButton.setToolTip(tooltip);
    groupControl.addControl(comboButton);

    return comboButton;
};

export const createConfig = (viewer, type, mode, icon, comboButton, lang) => {
    let markupExt = viewer?.getExtension('Autodesk.Viewing.MarkupsCore');
    var configButton = new window.Autodesk.Viewing.UI.Button(
        `${type}${mode.toUpperCase()}Button`
    );

    configButton.onClick = (ev) => {
        let styleObject = markupExt?.getStyle();
        const width = styleObject['stroke-width'];
        const font = styleObject['font-size'];
        const opacity = styleObject['stroke-opacity'];

        if (type === 'width')
            if (mode === 'increase') {
                if (width) styleObject['stroke-width'] = width * 2;
                if (font) styleObject['font-size'] = font * 1.5;
            } else {
                if (width) styleObject['stroke-width'] = width / 2;
                if (font) styleObject['font-size'] = font / 1.5;
            }
        else if (mode === 'increase') {
            if (opacity < 1) styleObject['stroke-opacity'] = opacity + 0.1;
        } else if (opacity > 0) styleObject['stroke-opacity'] = opacity - 0.1;

        markupExt?.setStyle(styleObject);

        comboButton.container.click();
    };

    const div = document.createElement('div');
    div.classList.add('w-full', 'h-full', 'flex', 'justify-center');
    div.innerHTML = icon;
    div.children[0].classList.add('self-center');

    configButton.container.children[0].appendChild(div);
    configButton.setToolTip(
        mode === 'increase' ? locale[lang].increase : locale[lang].decrease
    );
    comboButton.addControl(configButton);
};

export const createColor = (viewer, color, colorCode, comboButton) => {
    let markupExt = viewer?.getExtension('Autodesk.Viewing.MarkupsCore');
    var colorButton = new window.Autodesk.Viewing.UI.Button(`${color}Button`);
    colorButton.onClick = (ev) => {
        var styleObject = markupExt?.getStyle();
        styleObject['stroke-color'] = colorCode;
        markupExt?.setStyle(styleObject);
    };

    const div = document.createElement('div');
    div.classList.add('w-full', 'h-full', 'flex', 'justify-center');
    div.innerHTML = IcColor;

    colorButton.container.children[0].appendChild(div);
    const colorButtonStyle = colorButton.container.style;
    colorButtonStyle.color = color;
    comboButton.addControl(colorButton);
};
