import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { setCloseToolbar, setMarkupsToolbar } from '../../actions/toolbar';
import ViewerNotification from './ViewerNotification';
import { clearNotifications, clearViewer } from './../../actions/viewer';
import { mainContext } from './../context/mainContext';
import ViewerLoader from './ViewerLoader';

const Viewer = ({ name }) => {
    const dispatch = useDispatch();

    const loadingBar = useSelector((state) => state.loadingBar);

    const { setCurrentLinks, setLoadedPointClouds } = useContext(mainContext);

    useEffect(
        () => () => {
            dispatch(setMarkupsToolbar());
            dispatch(setCloseToolbar());
            dispatch(clearViewer());
            dispatch(clearNotifications());
            setCurrentLinks([]);
            setLoadedPointClouds([]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Helmet>
                <title>MODELIC | {name}</title>
            </Helmet>
            <div id="viewer"></div>
            <ViewerNotification />
            {loadingBar.viewer > 0 && (
                <div className="w-[calc(100%-40px)] h-[calc(100%+24px)] relative">
                    <ViewerLoader />
                </div>
            )}
        </>
    );
};

export default withRouter(Viewer);
