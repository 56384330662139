import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusColor } from '../../utils/colors';
import { IcIssueListArrow } from '../../share/icons';
import { setIssueStatus } from '../../actions/issue';
import locale from '../../utils/localization.json';

const StatusChip = ({ status, hasArrow }) => {
    const lang = useSelector((state) => state.language);

    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const wrapperRef = useRef(null);

    const statuses = [
        {
            key: 'To Do',
            value: locale[lang].toDo,
        },
        {
            key: 'In Progress',
            value: locale[lang].inProgress,
        },
        {
            key: 'In Review',
            value: locale[lang].inReview,
        },
        {
            key: 'Done',
            value: locale[lang].closed,
        },
    ];

    useEffect(() => {
        // Alert if clicked on outside of element
        function handleClickOutside({ target }) {
            if (wrapperRef.current && !wrapperRef.current.contains(target))
                setIsOpen(false);
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div className="relative text-modelic-gray-100 text-xs font-medium font-poppins">
            <button
                className={`h-[22px] ${
                    hasArrow ? 'pl-2 pr-1 min-w-[82px]' : 'pl-3 pr-2'
                } ${statusColor(status)} rounded-[20px] flex items-center`}
                disabled={!hasArrow}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="w-full pr-1 whitespace-nowrap">
                    {statuses?.find(({ key }) => key === status)?.value}
                </div>
                {hasArrow && (
                    <div className={isOpen ? 'rotate-180' : ''}>
                        {IcIssueListArrow}
                    </div>
                )}
            </button>
            {isOpen && (
                <div
                    className="absolute w-auto rounded-[11px] overflow-hidden min-w-full mt-1 flex flex-col z-20"
                    ref={wrapperRef}
                >
                    {statuses.map(({ key, value }) => (
                        <button
                            key={key}
                            className={`px-2 py-1.5 ${statusColor(key)}`}
                            onClick={() => {
                                if (key !== status)
                                    dispatch(setIssueStatus(key));
                                setIsOpen(false);
                            }}
                        >
                            {value}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StatusChip;
