import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { mainContext } from './../context/mainContext';
import { IcHome, IcSubMenu } from '../../share/icons';
import { clearViewer } from '../../actions/viewer';

const TopBar = () => {
    const { project_name } = useSelector((state) => state.currentProject);

    const { removeAllPointClouds } = useContext(mainContext);

    const dispatch = useDispatch();

    return (
        <div className="w-full flex-col justify-start items-center inline-flex">
            <div className="w-full h-10 justify-between items-center inline-flex">
                <div className="justify-start items-center gap-0.5 flex">
                    <Link
                        to="/"
                        className="text-modelic-gray-100 hover:text-modelic-primary-main"
                    >
                        <div
                            onClick={() => {
                                removeAllPointClouds();
                                dispatch(clearViewer());
                            }}
                        >
                            {IcHome}
                        </div>
                    </Link>
                    <div className="text-modelic-gray-600">{IcSubMenu}</div>
                    <div className="text-modelic-gray-600 text-xs font-normal font-poppins uppercase">
                        {project_name}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
