import { useState } from 'react';
import ItemContainer from './ToolContainer';
import ToolbarItem from './ToolbarItem';
import { IcCloseToolbar } from '../../icons';

// const tools = [];
// tools.push({
//     id: 'models',
//     icon: IcBox,
//     activeIcon: IcModel,
//     heading: 'models',
//     component: <div className="text-modelic-primary-lighter">MODELS</div>,
//     callback: () => console.log('models'),
// });

const Toolbar = ({ tools }) => {
    const [showTool, setShowTool] = useState(false);
    const [heading, setHeading] = useState();
    const [component, setComponent] = useState();
    const [openTool, setOpenTool] = useState('');

    return (
        <>
            <ItemContainer showTool={showTool} heading={heading}>
                {component}
            </ItemContainer>
            <div className="fixed right-0 w-11 h-full bg-modelic-gray-900 border-l-2 border-modelic-primary-main">
                <div className="w-full h-12 pr-2 pl-2.5 py-2.5 border-b-2 border-modelic-primary-main">
                    <button
                        id="toolbar-close"
                        className={`w-6 h-6 rotate-180 align-middle ${
                            showTool
                                ? 'text-modelic-primary-main'
                                : 'text-modelic-primary-lighter'
                        }`}
                        disabled={!showTool}
                        onClick={() =>
                            setShowTool(false) &
                            setOpenTool('') &
                            setComponent(null)
                        }
                    >
                        {IcCloseToolbar}
                    </button>
                </div>
                <ul className="pr-2 pl-2.5 pt-6 flex-col inline-flex gap-9">
                    {tools?.map((tool) => (
                        <ToolbarItem
                            key={tool.id}
                            tool={tool}
                            setShowTool={setShowTool}
                            setHeading={setHeading}
                            setComponent={setComponent}
                            openTool={openTool}
                            setOpenTool={setOpenTool}
                        />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Toolbar;
