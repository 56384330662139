const ToolbarItem = ({
    tool,
    setShowTool,
    setHeading,
    setComponent,
    openTool,
    setOpenTool,
}) => {
    return (
        <li>
            <button
                className={`w-6 h-6 text-modelic-primary-lighter hover:text-modelic-primary-main ${
                    openTool === tool.heading
                        ? 'text-modelic-primary-main'
                        : 'text-modelic-primary-lighter'
                }`}
                onClick={() => {
                    if (tool.heading) {
                        setShowTool(true);
                        setHeading(tool.heading);
                        setComponent(tool.component);
                        setOpenTool(tool.heading);
                    }

                    tool.callback();
                }}
            >
                {openTool === tool.heading && tool.activeIcon
                    ? tool.activeIcon
                    : tool.icon}
            </button>
        </li>
    );
};

export default ToolbarItem;
