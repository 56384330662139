import { Helmet } from 'react-helmet';
import locale from '../../utils/localization.json';
import { useSelector } from 'react-redux';

const NotFound = () => {
    const lang = useSelector((state) => state.language);
    return (
        <>
            <Helmet>
                <title>MODELIC | {locale[lang].notFound}</title>
            </Helmet>
            <div className="h-svh w-full flex items-center justify-center text-modelic-gray-100 font-poppins text-lg">
                404 | {locale[lang].pageNotFound}
            </div>
        </>
    );
};

export default NotFound;
