const error = (message) => {
    console.log('MODELIC: Error');
    console.log(message);
};

const info = (message) => console.log('MODELIC:' + message);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    error,
    info,
};
