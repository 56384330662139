import React from 'react';
import { IcLoading } from '../../share/icons';

const PageLoader = () => {
    return (
        <div className="h-svh w-full flex items-center justify-center">
            <div className="scale-[200%]">
                <div className="animate-spin text-modelic-primary-main">
                    {IcLoading}
                </div>
            </div>
        </div>
    );
};

export default PageLoader;
