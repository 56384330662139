import { hideLoading, showLoading } from 'react-redux-loading-bar';

import {
    addIssue,
    addMessage,
    allIssues,
    deleteIssue,
    updateIssue,
    getIssue,
    getAssigneeTeams,
    assignIssue,
    updateStatus,
} from './../services/issueService';
import { errorMessage, successMessage } from '../utils/message';
import locale from '../utils/localization.json';

export const showIssues = (show) => {
    return async (dispatch) => {
        await dispatch({
            type: 'SHOW_ISSUES',
            payload: show,
        });
    };
};

export const addNewIssue = (body) => {
    return async (dispatch) => {
        try {
            dispatch(showLoading());

            const { status, data } = await addIssue(body);

            if (status === 200) {
                await dispatch(getAllIssues());
                successMessage(data.message);
                return Promise.resolve();
            }
        } catch (err) {
            return Promise.reject(err);
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const getAllIssues = () => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentObject;

        try {
            dispatch(showLoading());

            const { status, data } = await allIssues(_id);
            if (status === 200) {
                await dispatch({ type: 'INIT_ISSUE', payload: data });
            }
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const emptyIssues = () => {
    return async (dispatch) => {
        await dispatch({ type: 'INIT_ISSUE', payload: [] });
    };
};

export const setCurrentIssue = (issueId) => {
    return async (dispatch, getState) => {
        const viewer = getState().viewer;
        const lang = getState().language;

        try {
            dispatch(showLoading());

            const { status, data } = await getIssue(issueId);
            if (status === 200) {
                if (
                    data?.view_role ===
                    viewer?.model?.getDocumentNode()?.data?.role
                ) {
                    await dispatch({ type: 'SET_ISSUE', payload: data });
                    return Promise.resolve(data);
                } else {
                    errorMessage(
                        locale[lang].issueWrongViewRole1 +
                            data?.view_role?.toUpperCase() +
                            locale[lang].issueWrongViewRole2
                    );
                    return Promise.reject();
                }
            }
        } catch (err) {
            return Promise.reject(err);
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const addNewMessage = (text) => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentIssue;

        try {
            dispatch(showLoading());

            const { status } = await addMessage(_id, text);
            if (status === 200) await dispatch(setCurrentIssue(_id));
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const handleIssueDelete = (issueId) => {
    return async (dispatch) => {
        try {
            dispatch(showLoading());

            const { status, data } = await deleteIssue(issueId);
            if (status === 200) {
                await dispatch(getAllIssues());
                successMessage(data.message);
            }
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const handleIssueEdit = (body) => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentIssue;

        try {
            dispatch(showLoading());

            const { status, data } = await updateIssue(_id, body);

            if (status === 200) {
                await dispatch(getAllIssues());
                successMessage(data.message);
                return Promise.resolve();
            }
        } catch (err) {
            return Promise.reject(err);
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const getAllTeams = () => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentIssue;

        try {
            dispatch(showLoading());

            const { status, data } = await getAssigneeTeams(_id);
            if (status === 200)
                await dispatch({ type: 'INIT_TEAMS', payload: data });
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const emptyCurrentIssue = () => {
    return async (dispatch) =>
        await await dispatch({ type: 'SET_ISSUE', payload: null });
};

export const emptyAllTeams = () => {
    return async (dispatch) =>
        await dispatch({ type: 'INIT_TEAMS', payload: null });
};

export const setAssigneeTeam = (assigneeId) => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentIssue;

        try {
            dispatch(showLoading());

            const { status } = await assignIssue(_id, assigneeId);
            if (status === 200) {
                dispatch(setCurrentIssue(_id));
                dispatch(getAllIssues());
            }
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};

export const setIssueStatus = (issueStatus) => {
    return async (dispatch, getState) => {
        const { _id } = getState().currentIssue;

        try {
            dispatch(showLoading());

            const { status } = await updateStatus(_id, issueStatus);
            if (status === 200) {
                dispatch(setCurrentIssue(_id));
                dispatch(getAllIssues());
            }
        } catch {
        } finally {
            dispatch(hideLoading());
        }
    };
};
