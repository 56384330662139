import "../instrument.js"
import React, { useEffect } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import socketIO from 'socket.io-client';

import PrivateLayout from './../components/Layouts/PrivateLayout';
import Models from './../components/Models/Models';
import MainContext from './../components/context/MainContext.jsx';
import Viewer from '../components/Viewer/Viewer';
import { handleModelTranslate } from './../actions/model';
import locale from './../utils/localization.json';
import NotFound from '../components/common/NotFound';
import PageRedirector from './../components/common/PageRedirector';
import { getAllPointClouds } from './../actions/pointcloud';

import * as Sentry from '@sentry/react';
// Create Custom Sentry Route component

import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const SentryRoute = Sentry.withSentryRouting(Route);


const Bimodel = () => {
    const currentObject = useSelector((state) => state.currentObject);
    const { permissions } = useSelector((state) => state.currentProject);
    const lang = useSelector((state) => state.language);

    const dispatch = useDispatch();

    useEffect(() => {
        const socket = socketIO(`${process.env.REACT_APP_OSS_SOCKET}/socket`, {
            transports: ['websocket'],
        });
        socket.on('update', ({ _id, versions }) => {
            dispatch(handleModelTranslate(_id, versions));
        });
        socket.on('analyzed', () => {
            dispatch(getAllPointClouds());
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Router history={history}>

            <Switch>
                <SentryRoute path={['/project', '/viewer']}>
                    <MainContext>
                        <PrivateLayout>
                            <Switch>
                                <SentryRoute
                                    path="/project/models"
                                    exact
                                    render={() =>
                                        permissions?.model?.download ? (
                                            <Models title={locale[lang].models} />
                                        ) : (
                                            <Redirect to="/" />
                                        )
                                    }
                                />
                                <SentryRoute
                                    path="/project/pointclouds"
                                    exact
                                    render={() =>
                                        permissions?.point_cloud?.download ? (
                                            <Models
                                                title={locale[lang].pointClouds}
                                            />
                                        ) : (
                                            <Redirect to="/" />
                                        )
                                    }
                                />
                                <SentryRoute
                                    path="/viewer"
                                    render={() =>
                                        !isEmpty(currentObject) ? (
                                            <Viewer name={currentObject.name} />
                                        ) : (
                                            <Redirect to="/" />
                                        )
                                    }
                                />
                                <SentryRoute
                                    path="*"
                                    render={() => <Redirect to="/not-found" />}
                                />
                            </Switch>
                        </PrivateLayout>
                    </MainContext>
                </SentryRoute>
                <SentryRoute path="/not-found" component={NotFound} />
                <SentryRoute
                    path="/"
                    exact
                    render={() =>
                        permissions?.model?.download ? (
                            <Redirect to="/project/models" />
                        ) : permissions?.point_cloud?.download ? (
                            <Redirect to="/project/pointclouds" />
                        ) : (
                            <PageRedirector />
                        )
                    }
                />
                <SentryRoute path="*" render={() => <Redirect to="/not-found" />} />
            </Switch>
        </Router>
    );
};

export default Bimodel;
