export const issuesReducer = (state = [], action) => {
    switch (action.type) {
        case 'INIT_ISSUE':
            return [...action.payload];
        case 'ADD_ISSUE':
            return [...action.payload];
        case 'RESOLVE_ISSUE':
            return [...action.payload];
        case 'EDIT_ISSUE':
            return [...action.payload];
        case 'DELETE_ISSUE':
            return [...action.payload];
        default:
            return state;
    }
};

export const currentIssueReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ISSUE':
            return { ...action.payload };
        case 'ADD_MESSAGE':
            return { ...state, messages: action.payload };
        default:
            return state;
    }
};

export const issuesVisibilityReducer = (state = false, action) => {
    switch (action.type) {
        case 'SHOW_ISSUES':
            return action.payload;
        default:
            return state;
    }
};

export const assigneeTeamsReducer = (state = false, action) => {
    switch (action.type) {
        case 'INIT_TEAMS':
            return action.payload && [...action.payload];
        default:
            return state;
    }
};
