const teams = [
    'bg-modelic-secendery-main',
    'bg-modelic-primary-dark',
    'bg-modelic-info-dark',
    'bg-modelic-warning-darker',
    'bg-modelic-success-darker',
    'bg-modelic-error-darker',
    'bg-modelic-secendery-light',
    'bg-modelic-primary-main',
    'bg-modelic-info-main',
    'bg-modelic-warning-dark',
    'bg-modelic-success-dark',
    'bg-modelic-secendery-dark',
    'bg-modelic-primary-darker',
    'bg-modelic-info-darker',
];

const status = [
    {
        value: 'To Do',
        color: 'bg-modelic-error-dark',
    },
    {
        value: 'In Progress',
        color: 'bg-modelic-error-main',
    },
    {
        value: 'In Review',
        color: 'bg-modelic-warning-main',
    },
    {
        value: 'Done',
        color: 'bg-modelic-success-main',
    },
];

export const teamColor = (index) => teams[index];

export const statusColor = (value) => status?.find((s) => s.value === value)?.color;
